@import url('https://fonts.googleapis.com/css2?family=Amatic+SC&display=swap');

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 0.15s linear;
  }
}

.head_banner {
  position: fixed;
  top: 0;
  font-size: initial;
  left: 80%;
  list-style-type: none;
}

a {
  text-decoration: none;
  color: white;
}

.letter-space {
  transition: letter-spacing 1s;
  margin-top: 40px;
}

.letter-space:hover {
  letter-spacing: 0.35em;
}

body {
  margin: 0;
  padding: 0;
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.info-wrapper {
  top: 30%;
  position: fixed;
  color: white;
  z-index: 1;
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  font-size: 20px;
}

.amatic-sc {
  font-family: 'Amatic SC', cursive;
  font-size: 45px;
}

.name {
  font-size: 60px;
  margin: 0;
}
@media screen and (max-width:768px){
  .amatic-sc {
    font-size: 35px;
  }

  .name {
    font-size: 50px;
  }
}

.button-revert {
  color: #fff;
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  appearance: none;
}