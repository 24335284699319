.universe {
  display: flex;
  flex-flow: column;
}

.sky {
  height: 60%;
  position: absolute;
  width: 100%;
  top: 0;
  margin: 0;
  padding: 0;
  background: black;
  display: flex;
  justify-content: center;
}

.star {
  position: absolute;
  animation: move-star 100s ease-in-out infinite;
  color: white;
}

@keyframes move-star {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-7000%);
  }
}

.horizon {
  height: 6%;
  width: 100%;
  top: 60%;
  position: absolute;
  background: linear-gradient(black, rgb(3, 30, 53));
}

.deep-sea {
  height: 19%;
  position: absolute;
  width: 100%;
  top: 65%;
  transform: rotate(180deg);
  z-index: 2;
  opacity: 0.8;
}

.shallow-sea {
  height: 20%;
  position: absolute;
  width: 100%;
  top: 65%;
  transform: rotate(180deg);
  z-index: 1;
  opacity: 0.4;
}

.beach {
  position: absolute;
  height: 35%;
  top: 65%;
  width: 100%;
  background: linear-gradient(rgb(3, 30, 53), #dcd3b2);
  display: flex;
  justify-content: center;
}

.github {
  height: fit-content;
  margin-top: auto;
}

#canvas {
  display: block;
}

@media screen and (max-width:768px){
  .github-logo {
    height: 75px;
  }
}